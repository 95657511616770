import React, {} from "react";
import { Transaction } from 'models/Order';
import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { formatCurrency } from 'helper/currency';
import { getPaymentType } from 'helper/order';
type TransactionProps = {
    transactionData: Transaction
  }

  type DetailsTextProps = {
    title: string
    value: string | boolean | JSX.Element
  }
const TransactionPanel = ({ transactionData }: TransactionProps) => {
  const totalAmount = parseFloat(transactionData.amount)/100;
  const splitDubpay = parseFloat(transactionData.amount_split_dub)/100;
  const splitGateway = parseFloat(transactionData.amount_split_gateway)/100;
  const liquidAmount = parseFloat(transactionData.amount)/ 100 - splitDubpay - splitGateway;

  const Header = (props: any) => {
    const {title} = props;
    return (
      <Box bg={"#d6d4d6"} paddingTop={"3"} paddingBottom={"3"}>
        <Text
          paddingLeft={"2"}
          fontSize={"1em"}
          color={"#363536"}
          fontWeight={"bold"}>
          {title}
        </Text>
      </Box>
    )
  }
  const DetailsText = ({ title, value }: DetailsTextProps) => {
    return (
      <Box textAlign={"left"} >
        <Text fontSize="18px" color={"#494e52"}>
          <strong>{title}</strong>
        </Text>
        <Text  fontSize="20px">
          {value}
        </Text>
      </Box>
    )
  }

  const CardComponent = () => {
    return (
      <Flex direction={"column"}>
        <Header title={"Cartão"}/>
        <Box margin={"5"}>
          <Flex direction={{base: "column", lg: "row"}} justifyContent={"space-between"}>
            <DetailsText title={"Primeiros 6 dígitos"} value={transactionData.card_bin} />
            <DetailsText title={"Últimos 4 dígitos"} value={transactionData.card_last4} />
          </Flex>
        </Box>
      </Flex>
    )
  }

  const CobrancaComponent = () => {
    return (
      <Flex direction={"column"}>
        <Header title={"Cobrança"}/>
        <Box margin={"5"}>
          <Flex direction={{base: "column", lg: "row"}} justifyContent={"space-between"}>
            <DetailsText title={"Tipo de Pagamento"} value={getPaymentType(transactionData.type.name)} />
            <DetailsText title={"Referência"} value={transactionData.charge_id} />
              {transactionData.type.name === "credit" && (
                <DetailsText
                title={"Valor da Parcela"}
                value={`${transactionData.installment.toString()}x de ${formatCurrency(totalAmount / transactionData.installment)}`}
                />
                )}
            <DetailsText title={"Valor Total"} value={"R$ " + formatCurrency(totalAmount)} />
          </Flex>
        </Box>
      </Flex>
    )
  }

  const DistribuicaoValoresComponent = () => {
    return (
       <Flex direction={"column"}>
          <Header title={"Distribuição de valores"}/>
          <Box margin={"5"}>
            <Flex direction={{base: "column", lg: "row"}} justifyContent={"space-between"}>
              <Box>
                <DetailsText title={"Valor bruto"} value={formatCurrency(totalAmount)} />
              </Box>
              <Box>
                <DetailsText title={"Split Dubpay"} value={formatCurrency(splitDubpay)} />
              </Box>
              <Box>
                <DetailsText title={"Split Gateway"} value={formatCurrency(splitGateway)} />
              </Box>
              <Box>
                <DetailsText title={"Split Seller"} value={formatCurrency(liquidAmount)} />
              </Box>
            </Flex>
          </Box>
        </Flex>
    )
  }

  const ResumeComponent = () => {
    return (
      <Flex direction={"column"}>
             <Header title={"Resumo da transação"}/>
            <Box margin={"5"}>
            <Flex direction={{base: "row", lg: "row"}} justifyContent={"space-between"}>
            <Flex direction={{base: "column", lg: "column"}} justifyContent={"space-between"}>
              <Box>
              <DetailsText title={"Provedor"} value={transactionData.acquirer_name} />
              </Box>
              <Box>
              <DetailsText title={"Mensagem"} value={transactionData.acquirer_message} />
              </Box>
              <Box>
              <DetailsText title={"Tipo da operação"} value={transactionData.operation_type} />
              </Box>
            </Flex>
            {/* <Divider paddingTop={"5"} marginBottom={"5"}/> */}
            <Flex direction={{base: "column", lg: "column"}} justifyContent={"space-between"}>
              <Box>
              <DetailsText title={"Status da transação"} value={transactionData.transaction_status} />
              </Box>
              {transactionData.due_at &&
                <Box>
                <DetailsText title={"Expira em"} value={transactionData.due_at} />
                </Box>
              }
              <Box>
              <DetailsText title={"Pago em"} value={transactionData.paid_at} />
              </Box>
            </Flex>
          </Flex>
            </Box>
          </Flex>
    )
  }

  const AntiFraudComponent = () => {
    return (
      <Flex direction={"column"}>
            <Header title={"Anti-fraude"}/>
            <Box margin={"5"}>
              <Flex direction={{base: "column", lg: "row"}} justifyContent={"space-between"}>
                <Box>
                  <DetailsText title={"Provedor"} value={transactionData.antifraud_response_provider_name} />
                </Box>
                <Box>
                  <DetailsText title={"Status"} value={transactionData.antifraud_response_status} />
                </Box>
                <Box>
                  <DetailsText title={"Score"} value={transactionData.antifraud_response_score} />
                </Box>
              </Flex>
            </Box>
          </Flex>
    )
  }
  return (
    <React.Fragment>

      <Flex direction={"column"} w={"800px"} gap={"10"}>
        {transactionData.type.name === "credit" && transactionData.card_bin && (
          <Box borderWidth="1px" borderRadius="lg">
            <CardComponent/>
          </Box>
        )}
        <Box borderWidth="1px" borderRadius="lg">
         <CobrancaComponent/>
        </Box>
        <Box borderWidth="1px" borderRadius="lg">
         <DistribuicaoValoresComponent/>
        </Box>
        <Box borderWidth="1px" borderRadius="lg">
          <ResumeComponent/>
        </Box>
        {transactionData.type.name === "credit" ?
        <Box borderWidth="1px" borderRadius="lg">
          <AntiFraudComponent/>
        </Box>: null}
      </Flex>
    </React.Fragment>
  )
}


export default TransactionPanel;
