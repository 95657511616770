import React from 'react';
import { Box, Button, Flex, Stack, Text } from '@chakra-ui/react';
import MoneyContainer from 'views/admin/accountsBalance/components/ProvidersBalance/components/MoneyContainer';
import { SellersBalance } from 'models/SellerBalance';
import SellerTransactions from 'views/admin/accountsBalance/components/SellersBalance/components/SellerTransactions';
import SellerWaitingFunds from 'views/admin/accountsBalance/components/SellersBalance/components/SellerWaitingFunds';
import WithdrawModal from 'views/admin/accountsBalance/components/ProvidersBalance/components/WithdrawModal';
import AnticipationModal from 'views/admin/accountsBalance/components/ProvidersBalance/components/AnticipationModal';
import WarrantyModal from 'views/admin/accountsBalance/components/ProvidersBalance/components/WarrantyModal';

type SellersBalanceDetailsProps = {
	selectedSeller: SellersBalance;
	setSelectedSeller: Function;
	refetchSellers: Function;
}

export default function SellerBalanceDetails({ selectedSeller, setSelectedSeller, refetchSellers }: SellersBalanceDetailsProps) {
	const [withdrawalModalOpen, setWithdrawalModalOpen] = React.useState(false);
	const [anticipationModalOpen, setAnticipationModalOpen] = React.useState(false);
	const [warrantyModalOpen, setWarrantyModalOpen] = React.useState(false);

	const handleUpdateSeller = (newSeller: SellersBalance) => {
		setSelectedSeller(newSeller);
		refetchSellers();
	}

	return (
		<>
			<Button onClick={() => setSelectedSeller(null)}>
				Voltar
			</Button>
			<Flex backgroundColor="white" px="10px" py="20px" borderRadius="20px" mt="20px" direction={{ base: 'column', lg: 'row' }}>
				<Box>
					<Box mb="20px" px="10px">
						<Text
							color="secondaryGray.900"
							fontSize="22px"
							fontWeight="700"
							lineHeight="100%"
						>
							{selectedSeller.first_name} {selectedSeller.last_name}
						</Text>
						<Text>{selectedSeller.email}</Text>
					</Box>
					<Box>
						<Flex px="10px">
							<Box>
								<MoneyContainer title="Saldo Disponível" value={selectedSeller.balance.balance} />
							</Box>
							<Box ml="60px">
								<MoneyContainer title="Lançamentos Futuros" value={selectedSeller.balance.waiting_funds} />
							</Box>
							<Box ml="60px">
								<MoneyContainer title="Total Sacado" value={selectedSeller.balance.total_withdrawn} />
							</Box>
							<Box ml="60px">
								<MoneyContainer title="Saldo de Garantia" value={selectedSeller.balance.warranty_tax} />
							</Box>
						</Flex>
					</Box>
				</Box>
				<Flex ml={{ base: '0', lg: '40px' }} mt={{ base: '20px', lg: '0' }} alignItems="flex-end">
					<Stack spacing={2} direction='column' align='center'>
						<Button
							colorScheme="purple"
							mr="10px"
							onClick={() => setWithdrawalModalOpen(true)}
						>
							Realizar Saque
						</Button>
						<Button
							colorScheme="purple"
							mr="10px"
							onClick={() => setAnticipationModalOpen(true)}
						>
							Antecipar Lançamentos
						</Button>

						<Button
							colorScheme="purple"
							mr="10px"
							onClick={() => setWarrantyModalOpen(true)}
						>
							Liberar Garantia
						</Button>
					</Stack>
				</Flex>
			</Flex>
			<WithdrawModal isOpen={withdrawalModalOpen} onClose={() => setWithdrawalModalOpen(false)} seller={selectedSeller} setSeller={handleUpdateSeller} />
			<AnticipationModal isOpen={anticipationModalOpen} onClose={() => setAnticipationModalOpen(false)} seller={selectedSeller} setSeller={handleUpdateSeller} />
			<WarrantyModal isOpen={warrantyModalOpen} onClose={() => setWarrantyModalOpen(false)} seller={selectedSeller} setSeller={handleUpdateSeller} />
			<SellerTransactions seller={selectedSeller} />
			<SellerWaitingFunds seller={selectedSeller} />
		</>
	);
}
