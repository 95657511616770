import React from 'react';
import { Box, Button, Heading } from '@chakra-ui/react';
import { ordersReport, sellerReports } from 'services/PaymentsApi/reports';
import { notifyError } from 'utils/notification';
import LoadingPage from 'components/loading/LoadingPage';
import { exportToExcel } from 'helper/sheet';

export default function Reports() {
  const [loading, setLoading] = React.useState(false);

  const generateSellerReport = async (providerKey: string) => {
    try {
      setLoading(true);
      const sellers = await sellerReports(providerKey)
      const date = new Date().toISOString().split('T')[0];
      exportToExcel(sellers, `${date}-Sellers-${providerKey}`)
    } catch (e) {
      notifyError('Erro ao gerar relatório de sellers');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const generateOrderReport = async (providerKey: string) => {
    try {
      setLoading(true);
      const orders = await ordersReport(providerKey)
      const date = new Date().toISOString().split('T')[0];
      exportToExcel(orders, `${date}-Orders-${providerKey}`)
    } catch (e) {
      notifyError('Erro ao gerar relatório de pedidos');
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box pt={{ base: "130px", md: "80px" }}>
      <LoadingPage isOpen={loading} />
      <Box backgroundColor='white' padding={30} borderRadius={10}>
        <Box>
          <Heading fontSize={28} marginBottom={3}>Relatório de Sellers</Heading>
          <Button onClick={() => generateSellerReport('pagarme')} colorScheme='green' marginRight={4}>Pagarme</Button>
          <Button onClick={() => generateSellerReport('zoop')} colorScheme='orange' marginRight={4}>Zoop</Button>
          <Button onClick={() => generateSellerReport('evermart')} colorScheme='blue' marginRight={4}>Evermart</Button>
          <Button onClick={() => generateSellerReport('k8bank')} colorScheme='purple' marginRight={4}>K8Bank</Button>
          <Button onClick={() => generateSellerReport('celcoin')} colorScheme='yellow' marginRight={4}>Celcoin</Button>
          <Button onClick={() => generateSellerReport('asaas')} colorScheme='yellow' marginRight={4}>Asaas</Button>
          <Button onClick={() => generateSellerReport('iugu')} colorScheme='brand'>Iugu</Button>
        </Box>
        <Box marginTop={6}>
          <Heading fontSize={28} marginBottom={3}>Relatório de Pedidos</Heading>
          <Button onClick={() => generateOrderReport('pagarme')} colorScheme='green' marginRight={4}>Pagarme</Button>
          <Button onClick={() => generateOrderReport('zoop')} colorScheme='orange' marginRight={4}>Zoop</Button>
          <Button onClick={() => generateOrderReport('evermart')} colorScheme='blue' marginRight={4}>Evermart</Button>
          <Button onClick={() => generateOrderReport('k8bank')} colorScheme='purple' marginRight={4}>K8Bank</Button>
          <Button onClick={() => generateOrderReport('celcoin')} colorScheme='yellow' marginRight={4}>Celcoin</Button>
          <Button onClick={() => generateOrderReport('asaas')} colorScheme='yellow' marginRight={4}>Asaas</Button>
          <Button onClick={() => generateOrderReport('iugu')} colorScheme='brand'>Iugu</Button>
        </Box>
      </Box>
    </Box>
  );
}
