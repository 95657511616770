import { useState, useEffect, useRef } from 'react';
import { SimpleGrid, Box, Button, Table, Thead, Tbody, Tr, Th, Td, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, color } from '@chakra-ui/react';
import { useUserStore } from 'store/reducers/user';
import { FaArrowLeft, FaArrowRight, FaFileDownload, FaCheck, FaTimes, FaThumbsUp, FaPaperPlane, FaComment, FaEnvelope, FaFlag, FaConnectdevelop, FaCloudDownloadAlt, FaWhatsapp } from 'react-icons/fa';
import $ from "jquery";
import "jquery-mask-plugin";
import axios from "axios";
import { EditorState, convertToRaw, ContentState, Modifier, AtomicBlockUtils, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { adminApi } from 'services/AdminApi/api';

export default function UserReports() {

  	const auth = useUserStore((state) => state.auth);
  
	const [title, setTitle] = useState('');
	const [priority, setPriority] = useState('Média');
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [isLoading, setIsLoading] = useState(false);
	const [modalResposta, setModalResposta] = useState(false);
	const [ticketNumber, setTicketNumber] = useState('');
	const [dataAberto, setAberto] = useState([]);
	const [dataAnalise, setAnalise] = useState([]);
	const [dataResposta, setResposta] = useState([]);
	const [dataSolucao, setSolucao] = useState([]);
	const [dataFinalizado, setFinalizado] = useState([]);
	const [expandedId, setExpandedId] = useState(null);
	const [selectedCardData, setSelectedCardData] = useState(null);
	const [dataselectb, setDataselectb] = useState([]);

	  
	const handleCloseResposta = () => {
		setModalResposta(false);
	};

	const handleOpenModal = (cardData: any) => {
		handlechat(cardData.ticket);
		setSelectedCardData(cardData);
		setModalResposta(true);
	};
	useEffect(() => {
		fetchDatabanco();
	  }, []);
	
	  const fetchDatabanco = async () => {
		try {
		  const responseB = await adminApi.get(
			"https://jsonpdataproxy.appspot.com/?url=https%3A%2F%2Fwww.bcb.gov.br%2Fpom%2Fspb%2Festatistica%2Fport%2FParticipantesSTRport.csv&max-results=1000&type=CSV&format=json"
		  );
	
		  const parsedDataB = responseB.data.data;
	
		  setDataselectb(parsedDataB);
		} catch (error) {
			console.error(error);
		}
	  };
	
	  const banco = (n: any) => {
		const bancoEncontrado = dataselectb.find((item) => item[2] === n);
		return bancoEncontrado ? bancoEncontrado[1] : null;
	  };

  const renderPreloader = () => {
		return isLoading ? (<div className="preloader-overlay">
		<div className="preloader-content">
		  <div className="preloader-spinner"></div>
		</div>
	  	</div>) : null;
	};

  useEffect(() => {
		fetchDat();
	}, []);

	const fetchDat = async () => {
		setIsLoading(true);
		try {
			const response = await adminApi.get(
			  `${process.env.REACT_APP_API_URL}/api/helpdeskshow`,
			  {
				headers: {
				  "Content-Type": "multipart/form-data",
				  Authorization: `Bearer ${auth.token}`
				}
			  }
			);
			const parsedData: Array<{
				id: number;
				user_id: string;
				ticket: string;
				titulo: string;
				descricao: string;
				prioridade: string;
				status: string;
				status_data: string;
				created_at: string;
				updated_at: string;
				status_code: string;
				arquivos: Array<{
					id: number;
					help_desks_id: number;
					titulo: string;
					arquivo: string;
					created_at: string;
					updated_at: string;
				}>;
			}> = response.data;
			
			const aberto = parsedData.filter(item => item.status_code === "0");
			const analise = parsedData.filter(item => item.status_code === "1");
			const resposta = parsedData.filter(item => item.status_code === "2");
			const solucao = parsedData.filter(item => item.status_code === "3");
			const finalizado = parsedData.filter(item => item.status_code === "4");
		
			setAberto(aberto);
			setAnalise(analise);
			setResposta(resposta);
			setSolucao(solucao);
			setFinalizado(finalizado);
			
			
		} catch (error) {
		  	console.log(error);
		  
		}finally {
		  	setIsLoading(false);

		}
	}
	const handleIncreaseStatus = (statusKey: any, ticketNumber: any) => {
		
		if(statusKey < 4 ){
			postData(statusKey, ticketNumber, 'mais');
		}
	  };
	  
	  const handleDecreaseStatus = (statusKey: any, ticketNumber: any) => {
		if(statusKey > 0){
			postData(statusKey, ticketNumber, 'menos');
		}
	  };
	  
	  const postData = async (status_code: any, ticket: any, sentido: any) => {
		setIsLoading(true);
		try {
			const formData = new FormData();
   
			// Append form data
			formData.append("status_code", status_code);
			formData.append("ticket", ticket);
			formData.append("sentido", sentido);
		  const response = await adminApi.post(
			`${process.env.REACT_APP_API_URL}/api/helpdeskmove`,
			formData,
			{ 	
				headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `Bearer ${auth.token}`
			  	}
			}
		  );
		  fetchDat();
		} catch (error) {

		}finally {
			setIsLoading(false);

	  	}
	  };




	  const boxStyle = {
		container: {
		  display: 'flex',
		},
		box: {
			width: '20%',
			borderRadius: '10px',
			border: '2px dashed black',
			height: '83vh',
			backgroundColor: '#f2f2f2',
			margin: '3px',
			padding: '3px',
			overflow: 'hidden',
		},
		title: {
		  padding: '8px',
		  borderBottom: '1px solid #ccc',
		},
		content: {
		  flex: 1,
		  height: '77vh',
		  overflow: 'auto',
		},
	  };
	  


	  const handleMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
		event.currentTarget.style.backgroundColor = 'rgba(128, 128, 128, 0.5)';
		event.currentTarget.style.opacity = '1';
	}
	
	const handleMouseOut = (event: React.MouseEvent<HTMLDivElement>) => {
		event.currentTarget.style.backgroundColor = 'transparent';
		event.currentTarget.style.opacity = '0.2';
	}

	  const renderCards = (tickets: any) => {
		return tickets.map((ticket: any) => (


			<div 
  key={ticket.id} 
  style={{
    position: 'relative',
    width: '100%', 
    backgroundColor: 'white', 
    color: 'black', 
    padding: '15px', 
    border: '1px solid #ccc', 
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', 
    borderRadius: '3px',
    marginBottom: '10px',
  }}
>
<div 
    style={{
		position: 'absolute',
		top: '0',
		left: '0px',
    }}
>
<FaFlag
        style={{ margin: '5px', color: ticket.prioridade === 'Baixa' ? 'green' : ticket.prioridade === 'Média' ? 'orange' : 'red' }}
      /> 	
</div>
<div style={{
		position: 'absolute',
		top: '0',
		left: '24px',
		fontSize: '9px'
    }}>Criado em: {formatDate(ticket.created_at)}</div>
<div 
    style={{
		position: 'absolute',
		top: '0',
		right: '0px',
		fontFamily: 'monospace',
		fontSize: '14px'
    }}
>
{ticket.ticket}	
</div>
  <h4 style={{
	paddingLeft: '10px',
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    marginBottom: '10px',
  }}>
	
    <div style={{ display: 'flex', alignItems: 'center', }}>
      {ticket.titulo}
    </div>
  </h4>
  <div dangerouslySetInnerHTML={{ __html: convertDescriptionToHtml(ticket.descricao) }}></div>
  <div 
    style={{
		height: '100%',
		width: '50px',
		position: 'absolute',
		top: '0',
		left: '0px',
		cursor: 'pointer',
		opacity: '0.2',
		backgroundColor: 'transparent',
		display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
    }}
    onClick={() => handleDecreaseStatus(ticket.status_code, ticket.id)}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMouseOut}
>
    <FaArrowLeft 
      style={{ color: 'black' }}
    />
</div>
<div 
style={{
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}}
>
  <button
    style={{
		backgroundColor: '#5aac44',
		color: 'white',
		border: 'none',
		boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
		padding: '5px 10px',
		borderRadius: '3px',
    }}
    onClick={() => handleOpenModal(ticket)}
  >
    Ver Detalhes
  </button>
</div>  
  <div 
    style={{
		height: '100%',
		width: '50px',
		position: 'absolute',
		top: '0',
		right: '0px',
		cursor: 'pointer',
		opacity: '0.2',
		backgroundColor: 'transparent',
		display: 'flex',
        alignItems: 'center',
        justifyContent: 'right',
    }}
    onClick={() => handleIncreaseStatus(ticket.status_code, ticket.id)}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMouseOut}
  >
    <FaArrowRight 
      style={{ color: 'black' }}
    />
  </div>
</div>

		));
	  };

	  function convertDescriptionToHtml(description: any) {
		if (!description) {
			return '';
		}
		const bloco = JSON.parse(description)
		const contentStateDesc = convertFromRaw(bloco);
		const html = convertToHTML(contentStateDesc);
		return html;
		}
		function zipcode_alter(n: any){
			let resultado = '';
			let parte1 = '';
			let parte2 = '';
			let numero = '';
			let posicao = 5;
			numero = n+"";
			numero = numero.replace(/\D/g, '')
			if(numero != ''){
			  parte1 = numero.slice(0, posicao);
			  parte2 = numero.slice(posicao);
			  resultado = parte1 + "-" + parte2;
			}
			return resultado;
		  }
		  
		  function datetime(n: any){
			if (typeof n === "string") {
			  const parts = n.split("-");
			  if (parts.length === 3) {
				const day = parts[2];
				const month = parts[1];
				const year = parts[0];
				return day + "/" + month + "/" + year;
			  }
			}
			return n;
		  }
		  
		  
		  function fone(n: any){
			if (typeof n === "string" && n.length === 11) {
			  const ddd = n.substring(0, 2);
			  const parte1 = n.substring(2, 3);
			  const parte2 = n.substring(3, 7);
			  const parte3 = n.substring(7);
			  return `(${ddd}) ${parte1} ${parte2}-${parte3}`;
			}
			return n;
		  }
		  
		  function cpf(n: any){
			if (typeof n === "string" && n.length === 11) {
			  const parte1 = n.substring(0, 3);
			  const parte2 = n.substring(3, 6);
			  const parte3 = n.substring(6, 9);
			  const parte4 = n.substring(9);
			  return `${parte1}.${parte2}.${parte3}-${parte4}`;
			}
			return n;
		  }
		  const [chatMessage, setChatMessage] = useState("");
		  const [chatResponses, setChatResponses] = useState([]);

		  const handlechat = async (ticket: any) => {


			try {
				const formData = new FormData();
				formData.append("ticket", ticket);

			const response = await adminApi.post(
				`${process.env.REACT_APP_API_URL}/api/helpdeskchat`,
				formData,
				{ 	
					headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${auth.token}`
					}
				}
			);

			setChatResponses(response.data);
			} catch (error) {

			}finally {


			}
		  };
		  
		  const handleSendMessage = async () => {
			
			if(chatMessage !== ''){
				setIsLoading(true);
				try {
					const formData = new FormData();
		
					// Append form data
					formData.append("id", '0');
					formData.append("texto", chatMessage);
					formData.append("ticket", selectedCardData.ticket);

				const response = await adminApi.post(
					`${process.env.REACT_APP_API_URL}/api/helpdeskresposta`,
					formData,
					{ 	
						headers: {
						"Content-Type": "multipart/form-data",
						Authorization: `Bearer ${auth.token}`
						}
					}
				);

				setChatResponses(response.data);
				setChatMessage("");
				} catch (error) {

				}finally {
					setIsLoading(false);

				}
			}
		  };

		  const chatBoxRef = useRef(null);

		  useEffect(() => {
			if (chatBoxRef.current) {
				chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
			}
		}, [chatResponses]);

		const handleKeyDown = (e: any) => {
			if (e.key === 'Enter' && !e.shiftKey) {
				e.preventDefault();
				handleSendMessage();
				
			}
		};
		
		  
		const  formatDate = (dateTimeString: any) => {
			const dateTime = new Date(dateTimeString);
			
			const day = String(dateTime.getDate()).padStart(2, '0');
			const month = String(dateTime.getMonth() + 1).padStart(2, '0');
			const year = dateTime.getFullYear();
			const hours = String(dateTime.getHours()).padStart(2, '0');
			const minutes = String(dateTime.getMinutes()).padStart(2, '0');
			const seconds = String(dateTime.getSeconds()).padStart(2, '0');
			
			return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
		}


		useEffect(() => {
			let interval: any;
			if (modalResposta && selectedCardData.status_code == 2) {
			  interval = setInterval(() => {
				handlechat(selectedCardData.ticket);
			  }, 5000);
			}
			return () => {
			  if (interval) {
				clearInterval(interval);
			  }
			};
		  }, [modalResposta]);
		  
	return(
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      	{renderPreloader()}

		  <Modal isOpen={modalResposta} onClose={handleCloseResposta} size='6xl'>
		  	<ModalOverlay />
			<ModalContent>
				<ModalHeader>{selectedCardData && selectedCardData.titulo} - {selectedCardData && selectedCardData.ticket}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
				<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>
					Data criação: {selectedCardData && formatDate(selectedCardData.created_at)}
				</div>
				<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>
					Data última atualização: {selectedCardData && formatDate(selectedCardData.updated_at)}
				</div>
				<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>
					Descrição: <div dangerouslySetInnerHTML={{ __html: convertDescriptionToHtml(selectedCardData && selectedCardData.descricao) }}/>
					<div style={{background: '#E5DDD5', padding: '10px', borderRadius: '5px', overflowY: 'scroll', maxHeight: '200px'}} ref={chatBoxRef}>
					{
						chatResponses.map((response) => (
							<div style={{display: 'flex', justifyContent: response.user_id === 0 ? 'flex-end' : 'flex-start'}}>
								<div style={{
									backgroundColor: response.user_id === 0 ? '#DCF8C6' : '#FFFFFF',
									padding: '5px',
									borderRadius: '8px',
									margin: '5px 0',
									maxWidth: '80%',
									wordBreak: 'break-word'
								}}>
									{response.mensagem}
								
								<div style={{ fontSize: '10px', color: 'gray', alignSelf: response.user_id === 0 ? 'flex-end' : 'flex-start' }}>
									{formatDate(response.created_at)}
								</div></div>
							</div>

						))
					}
					</div>
					{
						selectedCardData && selectedCardData.status_code == 2 ? (
							<div style={{display: 'flex', alignItems: 'center', border: '1px solid black', borderRadius: '3px', overflow: 'hidden'}}>
								<div style={{background: 'gray', padding: '10px'}}>
									<span style={{color: 'white'}}><FaEnvelope></FaEnvelope></span>
								</div>
								<input
									type="text"
									style={{ flex: 1, border: 'none', padding: '10px', borderRadius: '3px' }}
									value={chatMessage}
									onChange={(e) => setChatMessage(e.target.value)}
									onKeyDown={handleKeyDown} // Adicione este atributo para chamar a função handleKeyDown
									placeholder="Digite sua mensagem..."
								/>
								<button
									style={{ borderRadius: '3px', backgroundColor: 'green', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer' }}
									onClick={handleSendMessage} // Atualize o atributo para onClick ao invés de onKeyDown
								>
									Enviar
								</button>
							</div>

						) : null
					}
				</div>
				<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>
					Prioridade: {selectedCardData && selectedCardData.prioridade}
				</div>
				<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>
					Arquivos:
					{selectedCardData && selectedCardData.arquivos && selectedCardData.arquivos.map((arquivo: any) => (
						<div>
						{arquivo.titulo}
						<img
							src={`${process.env.REACT_APP_API_URL_IMAGE}/storage/` + arquivo.arquivo}
							alt="Thumbnail"
							style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', width: '100%', height: 'auto'}}
						/>
						<br></br>
						</div>
					))}
				</div>
				<div style={{border: '1px solid', boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.3)', padding: '3px', margin: '2px'}}>
				{selectedCardData && selectedCardData.user && selectedCardData.user.map((userman: any) => (
					<div>
						<table width={"100%"}>
						<tbody>
						<tr style={{ verticalAlign: 'top' }}>
							<td width={"60%"}>
							<tr>
								<td>
								<label>Nome:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.name}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Email:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.email}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CPF:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{cpf(userman.cpf)}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Data de Nascimento:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{datetime(userman.birth_date)}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CEP:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{zipcode_alter(userman.zipcode)}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Endereço:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.address}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Cidade:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.city}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Estado:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.state}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Complemento:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.complement}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Número:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.number}</div>
								</td>
							</tr>
							<hr></hr>
							<tr>
								<td>
								<label>Faturamento:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.invoicing}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Telefone:</label>
								</td>
								<td>
								<div
									style={{
									display: 'flex',
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								> 
								<a
								  href={`https://api.whatsapp.com/send?phone=${userman.cellphone}`}
								  target="_blank"
								  rel="noopener noreferrer"
								  style={{display: 'flex'}}
								>
									{fone(userman.cellphone)} <FaWhatsapp style={{ marginRight: '4px', color: 'lightgreen', fontSize: '26px' }} /></a>
								</div>
								</td>
							</tr>
							<hr></hr>
							<tr>
								<td>
								<label>Banco ID:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.banco_id + ' ' + banco(userman.banco_id)}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Agência:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.agencia}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Conta:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.conta}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Número PIX:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.n_pix}</div>
								</td>
							</tr>
							<hr></hr>
							<tr>
								<td>
								<label>CNPJ:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Razão social:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_razao}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>Nome Fantasia:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_social}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ CEP:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{zipcode_alter(userman.cnpj_zipcode)}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Endereço:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_address}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Cidade:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_city}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Estado:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_state}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Complemento:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_complement}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Número:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_number}</div>
								</td>
							</tr>
							<hr></hr>
							<tr>
								<td>
								<label>CNPJ Banco ID:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_banco_id + ' ' + banco(userman.cnpj_banco_id)}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Agência:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_agencia}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Conta:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_conta}</div>
								</td>
							</tr>
							<tr>
								<td>
								<label>CNPJ Número PIX:</label>
								</td>
								<td>
								<div
									style={{
									borderLeftWidth: '1px',
									borderBottomWidth: '1px',
									paddingLeft: '4px',
									paddingBottom: '2px',
									fontWeight: 'bold',
									minHeight: '20px',
									}}
								>{userman.cnpj_n_pix}</div>
								</td>
							</tr>
							</td>
						</tr>
						</tbody>
						</table>
  
					</div>
				))}
				</div>
				</ModalBody>
				<ModalFooter>
				<Button variant="ghost" mr={3} onClick={handleCloseResposta}>
					Fechar
				</Button>
				</ModalFooter>
			</ModalContent>
			</Modal>





			<Box overflow={"auto"} maxHeight={"85vh"} minHeight={"85vh"}>
				<div style={boxStyle.container}>
					<div style={boxStyle.box}>
						<div style={boxStyle.title}>
							<p style={{ fontWeight: 'bold' }}>Aberto</p>
						</div>
						<div style={boxStyle.content}>
							{renderCards(dataAberto)}
						</div>
						</div>
					<div style={boxStyle.box}>
						<div style={boxStyle.title}>
							<p style={{ fontWeight: 'bold' }}>Análise</p>
						</div>
						<div style={boxStyle.content}>
							{renderCards(dataAnalise)}
						</div>
						</div>
					<div style={boxStyle.box}>
						<div style={boxStyle.title}>
							<p style={{ fontWeight: 'bold' }}>Resposta</p>
						</div>
						<div style={boxStyle.content}>
							{renderCards(dataResposta)}
						</div>
						</div>
					<div style={boxStyle.box}>
						<div style={boxStyle.title}>
							<p style={{ fontWeight: 'bold' }}>Solução</p>
						</div>
						<div style={boxStyle.content}>
							{renderCards(dataSolucao)}
						</div>
						</div>
					<div style={boxStyle.box}>
						<div style={boxStyle.title}>
							<p style={{ fontWeight: 'bold' }}>Finalizado</p>
						</div>
						<div style={boxStyle.content}>
							{renderCards(dataFinalizado)}
						</div>
					</div>
				</div>
			</Box>
		</Box>
	);

}



