import { paymentsApi } from "./api";
import {
  ProviderBalance,
} from 'models/ProviderBalance';
import {
  AnticipationSelectedWaitingFunds,
  SellersBalanceResponse,
  SellerTransactionsResponse,
  SellerWaitingFunds,
  SellerWaitingFundsResponse
} from 'models/SellerBalance';

export const getDubpayBalance = async (): Promise<ProviderBalance[]> => {
  const url = `/dubpay_balance/balance`;

  const response = await paymentsApi.get(
    url
  );

  return response.data.data;
};

export const getSellersBalance = async (page: number, filter: Record<string, string> = {}): Promise<SellersBalanceResponse> => {
  const url = `/seller_balance/sellers`;

  const params: Record<string, any> = {
    page
  }

  for (const key in filter) {
    if (filter[key]) {
      params[key] = filter[key];
    }
  }

  const response = await paymentsApi.get(
    url,
    { params }
  );

  return response.data;
};

export const getSellerTransactions = async (sellerId: number, page: number): Promise<SellerTransactionsResponse> => {
  const url = `/seller_balance/transactions/${sellerId}`;

  const response = await paymentsApi.get(
    url,
    {
      params: {
        page
      }
    }
  );

  return response.data;
};

export const getSellerWaitingFunds = async (sellerId: number, page: number): Promise<SellerWaitingFundsResponse> => {
  const url = `/seller_balance/waiting_funds/${sellerId}`;

  const response = await paymentsApi.get(
    url,
    {
      params: {
        page
      }
    }
  );

  return response.data;
}

export const getSellerAvailableWaitingFunds = async (sellerId: number): Promise<SellerWaitingFunds[]> => {
  const url = `/seller_balance/available_waiting_funds/${sellerId}`;

  const response = await paymentsApi.get(
    url,
  );

  return response.data.data;
}

export const withdrawSellerBalance = async (sellerId: number, amount: number): Promise<void> => {
  const url = `/seller_balance/withdraw`;

  await paymentsApi.post(
    url,
    {
      seller_id: sellerId,
      value: amount
    }
  );
}

export const withdrawWarrantySellerBalance = async (sellerId: number, amount: number): Promise<void> => {
  const url = `/seller_balance/withdraw_warranty`;

  await paymentsApi.post(
    url,
    {
      seller_id: sellerId,
      value: amount
    }
  );
}

export const anticipateSellerBalance = async (sellerId: number, selectedAnticipations: AnticipationSelectedWaitingFunds): Promise<void> => {
  const url = `/seller_balance/anticipate`;

  await paymentsApi.post(
    url,
    {
      seller_id: sellerId,
      declared_value: selectedAnticipations.amount,
      waiting_funds: selectedAnticipations.selectedWaitingFundsIds,
    }
  );
}
