import { Box } from '@chakra-ui/react';
import DashboardTable from './components/DashboardTable';
import { useEffect } from 'react';
import { useAuth } from 'hooks/authHook'; // Importando o hook de autenticação

export default function Dashboard() {
  const { check } = useAuth(); // Obtendo o método check do hook de autenticação

  useEffect(() => {
    check(); // Chamando o método check quando o componente for montado
  }, [check]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <DashboardTable />
    </Box>
  );
}
