import React, { useEffect } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import Users from './components/Users';
import { ContextProvider } from './variables/localContext';
import { useAuth } from '../../../hooks/authHook'; // Ajuste o caminho para importar corretamente

export default function MenuConsultas() {
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const { check } = useAuth(); // Utilize o hook de autenticação

  useEffect(() => {
    check();
  }, [check]);

  return (
    <ContextProvider>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Users />
      </Box>
    </ContextProvider>
  );
}
