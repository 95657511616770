import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Collapse,
  SimpleGrid,
  Text,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { MdDelete, MdModeEdit, MdCheck, MdClose } from "react-icons/md";
import { RowObj } from "../models/rowPaymentRequestObj";
import { GetUsers } from "../models/userData";
import CheckTable from "./CheckTableUser";
import {
  PaymentAdvanceRequest,
  GetPaymentAdvanceRequest,
} from "../../../../services/AdminApi/paymentAdvanceRequest";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useContextPlan } from "../../../../hooks/paymentRequestContext";
import { transformDateToString } from "../../../../helper/formatDateToString";
import { useContextLocal } from "../variables/localContext";
import FilterUser from "./FilterUser";

export default function PaymentPlans() {
  // Chakra Color Mode
  const {
    getProcessedRequests,
    processedRequests,
    loading,
    approvePaymentRequest,
    cancelPaymentRequest,
  } = useContextPlan();

  const {
    show,
    page,
    invoicing,
    limit,
    createdAt,
    name,
    situation,
    setParams,
    setOpenDeleteModal,
    setOpenUpdateModal,
    setIdPlan,
  } = useContextLocal();

  // const handleSituation = (users: User) => {
  //   if (users.total_stores == 0) return "Inativo";

  //   return "Ativo e inoperante";
  // };
  useEffect(() => {
    (async () => {
      await getProcessedRequests();
    })();
  }, []);

  const formatDate = (date: Date): string => {
    const data = new Date(date);
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = String(data.getFullYear());

    const dataFormatada = `${dia}/${mes}/${ano}`;
    return dataFormatada;
  };
  useEffect(() => {
    (async () => {
      const userTable: RowObj[] = processedRequests.map(
        (value: PaymentAdvanceRequest) => {
          return {
            id: value.id,
            user_id: value.user_id,
            name: value.name,
            status: value.status,
            amount: (Number(value.amount) / 100).toFixed(2).toString().padEnd(2, "0"),
            type: value.type,
            date: formatDate(value.created_at),
            updated_at: formatDate(value.updated_at),
          };
        }
      );

      setData(userTable);
    })();
  }, [processedRequests]);

  const columnHelper = createColumnHelper<RowObj>();

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [data, setData] = React.useState<RowObj[]>([]);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Nome do usuário
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("amount", {
      id: "amount",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Valor (R$)
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("type", {
      id: "type",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Tipo
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue() === "anticipation" ? "Antecipação" : "Saque"}
        </Text>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Criado em
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("updated_at", {
      id: "updated_at",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Atualizado em
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Status
        </Text>
      ),
      cell: (info) => {
        const status = info.getValue();
        const statusMap = {
          pending: "Pendente",
          approved: "Aprovado",
          cancelled: "Cancelado",
        };
        return (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {statusMap[status]}
          </Text>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      // // columnFilters: []
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  if (!loading) {
    return <React.Fragment />;
  } else {
    return (
      <React.Fragment>
        <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
          <Collapse in={show}>
            <FilterUser />
          </Collapse>

          <Box pt={{ base: "130px", md: "60px", xl: "40px" }}>
            <CheckTable
              tableData={data}
              table={table}
              tableName="Solicitação de recebíveis"
            />
            {/* <Pagination tableData={data} table={table} tableName="Lojas" /> */}
          </Box>
        </SimpleGrid>
      </React.Fragment>
    );
  }
}
