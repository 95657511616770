import {adminApi} from "./api"
import { useUserStore } from 'store/reducers/user';

export const getSellers = async (params?: GetSellers.Params): Promise<GetSellers.Result> => {
    try {
        const url = '/api/seller';
        const token = useUserStore.getState().auth.token;

        const result = await adminApi.get(`${process.env.REACT_APP_API_URL}${url}`,{
            params,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return {
            success: true,
            data: result.data
        };
    } catch (err) {
        return {
            success: false,
            data: err
        }
    }
}



export namespace GetSellers {
    export type Params = {
        name?: string;
        email?: string;
        analise?: string | string[];
        cellphone?: string;
        mail_date_code?: string;
        cnpj_razao?: string;
        mail_active?: boolean;
        activated?: boolean;
    }

    export type Result = {
        success: boolean;
        data: any
    }
}