import React from 'react';
import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text
} from '@chakra-ui/react';
import { formatCurrency } from 'helper/currency';
import { useForm } from 'react-hook-form';
import { DubpayWithdrawRequest } from 'models/ProviderBalance';
import { notifyError, notifySuccess } from 'utils/notification';
import { SellersBalance } from 'models/SellerBalance';
import MoneyInput from 'components/moneyInput/MoneyInput';
import { withdrawWarrantySellerBalance } from 'services/PaymentsApi/accountsBalance';

type WarrantyModalProps = {
	isOpen: boolean;
	onClose: () => void;
	seller: SellersBalance;
	setSeller: Function;
};

export default function WarrantyModal({ isOpen, onClose, seller, setSeller }: WarrantyModalProps) {
	const sellerName = `${seller.first_name} ${seller.last_name}`;
	const [confirmWarrantyTab, setConfirmWarrantyTab] = React.useState(false);
	const [warrantyAmount, setWarrantyAmount] = React.useState(null);
	const [isWithdrawing, setIsWithdrawing] = React.useState(false);

	const closeConfirmWarrantyTab = () => {
		setWarrantyAmount(null);
		setConfirmWarrantyTab(false);
	}

	const handleClose = () => {
		closeConfirmWarrantyTab();
		onClose();
	}

	const confirmWithdraw = async (data: any) => {
		const amount = parseInt(data.amount.replace(',', '').replace('.', ''));

        if (amount > seller.balance.warranty_tax) {
            notifyError('O valor de saque excede o saldo disponível de garantia.');
            return;
          }
          
		setWarrantyAmount(amount);
		setConfirmWarrantyTab(true);
	}

	const handleWithdraw = async (amount: number) => {
		try {
			setIsWithdrawing(true);
			await withdrawWarrantySellerBalance(seller.id, amount);

			setSeller({
				...seller,
				balance: {
					...seller.balance,
					balance: seller.balance.balance + amount,
                    warranty_tax: seller.balance.warranty_tax - amount
				}
			});

			notifySuccess(`Saque de ${formatCurrency(amount, 2)} realizado com sucesso!`);

			handleClose();
		} catch (e: any) {
			if (e?.response?.data?.message && typeof e.response.data.message === 'string') {
				notifyError('Erro: ' + e.response.data.message);
			} else {
				notifyError('Erro ao sacar!');
			}
		} finally {
			setIsWithdrawing(false);
		}
	}

	const {
		watch,
		handleSubmit,
		setValue,
	} = useForm<DubpayWithdrawRequest>();
	const amountValue = watch('amount');

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Liberar Garantia</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<Text><b>Seller:</b> {sellerName}</Text>
					<Text mb="10px"><b>Saldo de Garantia:</b> {formatCurrency(seller.balance.warranty_tax, 2)}</Text>
					{confirmWarrantyTab ? (
						<Box>
							<Box>
								<Text>Valor da retirada do saldo de garantia</Text>
								<Text fontSize="22px" fontWeight="bold">{formatCurrency(warrantyAmount, 2)}</Text>
							</Box>
							<Box>
								<Text>Saldo de garantia após saque</Text>
								<Text fontSize="22px" fontWeight="bold">{formatCurrency(seller.balance.warranty_tax - warrantyAmount, 2)}</Text>
							</Box>
							<Box display="flex" justifyContent="right" my="10px">
								<Button type='submit' onClick={closeConfirmWarrantyTab} variant="outline"  mr="10px">
									Voltar
								</Button>
								<Button type='submit' onClick={() => handleWithdraw(warrantyAmount)} colorScheme="green" disabled={isWithdrawing}>
									{isWithdrawing ? 'Sacando...' : 'Confirmar'}
								</Button>
							</Box>
						</Box>
					) : (
						<form onSubmit={handleSubmit(confirmWithdraw)}>
							<FormControl>
								<FormLabel htmlFor="amount">Valor</FormLabel>
								<MoneyInput id="amount" value={amountValue} setValue={setValue} />
								<Box display="flex" justifyContent="right">
									<Button type='submit' colorScheme="green" my="10px">
										Sacar
									</Button>
								</Box>
							</FormControl>
						</form>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
}
