import React from 'react';
import { Box, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { formatCurrency } from 'helper/currency';
import PaginationButtons from 'components/paginationButtons/PaginationButtons';
import ShowFilters from 'components/filter/ShowFilters';
import SellerBalanceFilters
	from 'views/admin/accountsBalance/components/SellersBalance/components/SellerBalanceFilters';
import { SellersBalanceResponse } from 'models/SellerBalance';

type SellersBalanceTableProps = {
	sellersResponse: SellersBalanceResponse;
	page: number;
	setPage: Function;
	setFilters: Function;
	setSelectedSeller: Function;
}

export default function SellersBalanceTable({ sellersResponse, page, setPage, setFilters, setSelectedSeller }: SellersBalanceTableProps) {
	const [showFilters, setShowFilters] = React.useState<boolean>(false);

	return (
		<Box backgroundColor="white" px="10px" py="20px" borderRadius="20px" mt="20px">
			<Box display="flex" justifyContent="space-between" alignContent="center" mb="20px" px="10px">
				<Text
					color="secondaryGray.900"
					fontSize="22px"
					fontWeight="700"
					lineHeight="100%"
				>
					Saldo dos Sellers
				</Text>
				<ShowFilters showFilters={showFilters} setShowFilters={setShowFilters} />
			</Box>
			<SellerBalanceFilters showFilters={showFilters} setFilters={setFilters} />
			<TableContainer>
				<Table variant='simple'>
					<Thead>
						<Tr>
							<Th>Nome</Th>
							<Th>Email</Th>
							<Th>Saldo</Th>
							<Th>Saldo Futuro</Th>
							<Th>Total Sacado</Th>
							<Th>Saldo de garantia</Th>
						</Tr>
					</Thead>
					<Tbody>
						{sellersResponse && sellersResponse.data.map(seller => (
							<Tr key={seller.id} _hover={{ backgroundColor: '#efefef' }} cursor="pointer" onClick={() => setSelectedSeller(seller)}>
								<Td>{seller.first_name} {seller.last_name}</Td>
								<Td>{seller.email}</Td>
								<Td>{formatCurrency(seller.balance.balance, 2)}</Td>
								<Td>{formatCurrency(seller.balance.waiting_funds, 2)}</Td>
								<Td>{formatCurrency(seller.balance.total_withdrawn, 2)}</Td>
								<Td>{formatCurrency(seller.balance.warranty_tax, 2)}</Td>
							</Tr>
						))}
					</Tbody>
				</Table>
				<Box display="flex" alignItems="center" mt="20px">
					<PaginationButtons onClick={setPage} currentPage={page} lastPage={sellersResponse?.meta?.last_page} />
				</Box>
			</TableContainer>
		</Box>
	);
}
