import { adminApi } from "./api";

export const getToken = async (params: GetToken.Params): Promise<GetToken.Result> => {
  const adminUrl = `/api/login`;
  try {
    
    const tokenResponse = await adminApi.post(
      `${process.env.REACT_APP_API_URL}${adminUrl}`,
      {
        email: params.email,
        password: params.password,
      }
    );

    const dataResponse: GetToken.Result = tokenResponse.data;

    localStorage.setItem('token', dataResponse.token);
    
    return dataResponse;
  } catch (err) {
    console.log(err);
  }
};

export namespace GetToken {
  export type Params = {
    email: string;
    password: string;
  };

  export type Result = {
    data: {
      id: number;
      name: string;
      email: string;
      role: {
        id: number;
        name: string;
      };
      activated: boolean;
      mail_active: boolean;
      zipcode: string | null;
    };
    token_shopify: string | null;
    token: string;
  };
}

