import { OrderResponse, PaymentTypeResponse, TransactionStatusResponse } from 'models/Order';
import { filtersToUrl } from 'helper/filters';
import { adminApi } from './api';

export async function cancelOrder(orderId: string, paymentMethod: string, token: string) {
  const response = await adminApi.delete(
    `${process.env.REACT_APP_API_URL}/api/cancel-order/${orderId}?payment_type=${paymentMethod}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return response.data;
}

export const getAllOrders = async (page: number, filters = {}): Promise<OrderResponse> => {
  const filtersUrl = filtersToUrl(filters);
  const adminUrl = `/api/admin/orders?page=${page}${filtersUrl}`;
  try {
    const orders = await adminApi.get<OrderResponse>(
      `${process.env.REACT_APP_API_URL}${adminUrl}`
    );

    return orders.data;
  } catch (err) {
    console.log(err);
  }
}
export const getAllPaymentTypes = async (): Promise<PaymentTypeResponse> => {
  try {
    const url = `/api/admin/payment-methods`;
    const response = await adminApi.get<PaymentTypeResponse>(
      `${process.env.REACT_APP_API_URL}${url}`
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
}

export const getAllTransactionStatuses = async (): Promise<TransactionStatusResponse> => {
  try {
    const url = `/api/admin/transaction-statuses`;
    const response = await adminApi.get<TransactionStatusResponse>(
      `${process.env.REACT_APP_API_URL}${url}`
    );

    return response.data;
  } catch (err) {
    console.log(err);
  }
}
